module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="computeViewHeader" class="headerContainer">\n\n    ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n</div>\n\n\n<div id="awsCreateRoleContainerPageView" class="pageView">\n\n  <div class="facetBar" id="awsCreateRolePageFacetPicker">\n    <ul class="facets">\n      <li><a id="resourceLink" href="javascript:void(0)">\n          ' +
((__t = ( computeLinkText )) == null ? '' : __t) +
'\n        </a></li>\n      <li><a id="credentialLink" href="javascript:void(0)">\n          ' +
((__t = ( credentialLinkText )) == null ? '' : __t) +
'\n        </a></li>\n    </ul>\n  </div>\n\n  <div id="awsCreateRoleContainer" class="awsCreateRoleContainer">\n\n    <form id="addCredsForm" class="addCredsForm">\n      <div id="dialogHead" class="dialogHead">\n        <h3>\n          ' +
((__t = ( addCredsTitle )) == null ? '' : __t) +
'\n        </h3>\n      </div>\n      <div id="dialogBody" class="dialogBody">\n\n        <div id="infoField"> </div>\n        <div class="row" id="awsLoginRow">\n          <div class="col-sm-12 add_margin_10">\n            <div class="form-group  has-feedback">\n              <div class="input-group mb-3 mt-3">\n                <div class="input-group-text" id="accessKeyLabel">\n                  ' +
((__t = ( awsAccessKey )) == null ? '' : __t) +
'\n                </div>\n                <input class="form-control" id="access_key" name="access_key" type="text" maxlength="20" autocomplete="off">\n              </div>\n            </div>\n            <div class="form-group  has-feedback">\n              <div class="input-group mb-3 mt-3">\n                <div class="input-group-text" id="secretAccessKeyLabel">\n                  ' +
((__t = ( awsSecretAccessKey )) == null ? '' : __t) +
'\n                </div>\n                <input class="form-control" id="secret_access_key" name="secret_access_key" type="password" maxlength="40" autocomplete="off">\n              </div>\n            </div>\n            <div class="form-group  has-feedback">\n              <div class="input-group mb-3 mt-3">\n                <div class="input-group-text" id="sessionTokenLabel">\n                  ' +
((__t = ( awsSessionToken )) == null ? '' : __t) +
'\n                </div>\n                <textarea class="form-control" id="session_token" name="session_token" type="text" rows="3" maxlength="1000" autocomplete="off" ></textarea>\n              </div>\n              <div class="input-group">\n                <div>\n                  ' +
((__t = ( awsSessionTokenOptional )) == null ? '' : __t) +
'\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div id="dialogFoot" class="dialogFoot">\n        <button class="btn btn_color_blue companion_btn" id="closeCreateDialog" type="button">' +
((__t = ( cancelButtonText )) == null ? '' : __t) +
'</button>\n        <button class="btn btn_color_blue add_indent_10" id="addAwsCredsButton" disabled=\'disabled\' type="submit">' +
((__t = ( nextBtnText )) == null ? '' : __t) +
'</button>\n      </div>\n\n    </form>\n\n\n\n\n  </div>\n\n</div>\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
