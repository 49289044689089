module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="countdown" title="' +
((__t = ( terminationPolicyWarning )) == null ? '' : __t) +
'">\n  <span class="countdown" ></span>\n</div>';

}
return __p
};
