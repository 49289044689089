module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="computeViewHeader" class="headerContainer">\n\n    ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n</div>\n\n<div id="awsImportRoleContainerPageView" class="pageView">\n\n  <div class="facetBar" id="awsImportRolePageFacetPicker">\n    <ul class="facets"><li><a id="resourceLink" href="javascript:void(0)">' +
((__t = ( computeLinkText )) == null ? '' : __t) +
'</a></li><li><a id="credentialLink" href="javascript:void(0)">' +
((__t = ( credentialLinkText )) == null ? '' : __t) +
'</a></li></ul>\n  </div>\n\n  <div id="awsImportRoleContainer" class="awsImportRoleContainer">\n\n    <form id="addCredsForm" class="addCredsForm">\n      <div id="dialogHead" class="dialogHead">\n          <h3>' +
((__t = ( addCredsTitle )) == null ? '' : __t) +
'</h3>\n          <h2>' +
((__t = ( addCredsPurpose )) == null ? '' : __t) +
'</h2>\n      </div>\n      <div id="dialogBody" class="dialogBody">\n\n          <div id="infoField"> </div>\n          <input id="defaultRoleNameExtension" type="hidden" value="" ></input>\n\n          <div class="row importSteps" id="step1row">\n              <div class="col-sm-2 text-center"><strong>' +
((__t = ( step1 )) == null ? '' : __t) +
'</strong></div>\n              <div class="col-sm-10 add_margin_10 stepContent">\n                <p>' +
((__t = ( step1Text )) == null ? '' : __t) +
'</p>\n                <button type="button" id="quickAWSImporting" class="btn btn_color_blue credImportBtn">' +
((__t = ( step1ButtonText )) == null ? '' : __t) +
'</button>\n              </div>\n          </div>\n\n          <div class="row importSteps" id="step2row">\n              <div class="col-sm-2 text-center"><strong>' +
((__t = ( step2 )) == null ? '' : __t) +
'</strong></div>\n              <div class="col-sm-10 add_margin_10 stepContent">\n                <p>' +
((__t = ( step2Text1 )) == null ? '' : __t) +
'<br />' +
((__t = ( step2Text2 )) == null ? '' : __t) +
'</p>\n                <button type="button" id="linkToAwsConsole" class="btn btn_color_blue credImportBtn">' +
((__t = ( step2ButtonText )) == null ? '' : __t) +
'</button>\n              </div>\n          </div>\n\n\n          <div class="col-sm-12 stepTable">\n\n                <div class="row stepRow" id="step3row">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 text-center stepTd"><strong>' +
((__t = ( step3 )) == null ? '' : __t) +
'</strong></div>\n                    <div class="col-sm-8 add_margin_10 stepTd">' +
((__t = ( step3Text1 )) == null ? '' : __t) +
'</div>\n                </div>\n\n                <div class="row stepRow" id="step4row">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 text-center stepTd"><strong>' +
((__t = ( step4 )) == null ? '' : __t) +
'</strong></div>\n                    <div class="col-sm-8 add_margin_10 stepTd">' +
((__t = ( step4Text )) == null ? '' : __t) +
'</div>\n                </div>\n\n                <div class="row stepRow" id="acctIdrow">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 stepTd"></div>\n                    <div class="col-sm-6 add_margin_10 stepTd">\n                      <div class="form-group">\n                          <div class="input-group mb-2 mt-2">\n                              <div class="input-group-text" id="acctIdlabel">' +
((__t = ( acctId )) == null ? '' : __t) +
'</div>\n                                  <input type="text" class="form-control input-group-text" id="accountId" value="' +
((__t = ( acctIdValue )) == null ? '' : __t) +
'" readonly="readonly">\n                              <span class="input-group-btn">\n                                  <button class="btn btn_color_blue copyIPValueButton" type="button" id="adminAccountCopy" data-bs-toggle="tooltip" data-bs-placement="bottom" title="' +
((__t = ( copyButton )) == null ? '' : __t) +
'">\n                                      <div class="copyTextIcon"></div>\n                                  </button>\n                              </span>\n                          </div>\n                      </div>\n                    </div>\n                </div>\n\n                <div class="row stepRow" id="step5row">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 text-center stepTd"><strong>' +
((__t = ( step5 )) == null ? '' : __t) +
'</strong></div>\n                    <div class="col-sm-8 add_margin_10 stepTd">' +
((__t = ( step5Text )) == null ? '' : __t) +
'</div>\n                </div>\n\n                <div class="row stepRow" id="extIdrow">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 stepTd"></div>\n                    <div class="col-sm-6 add_margin_10 stepTd">\n                        <div class="form-group">\n                            <div class="input-group mb-2 mt-2">\n                                <div class="input-group-text" id="extIdLabel">' +
((__t = ( extId )) == null ? '' : __t) +
'</div>\n                                <input type="text" class="form-control input-group-text" id="externalId" value="' +
((__t = ( extIdValue )) == null ? '' : __t) +
'" readonly="readonly">\n                                <span class="input-group-btn">\n                                    <button class="btn btn_color_blue copyIPValueButton" type="button" id="externalIdCopy" data-bs-toggle="tooltip" data-bs-placement="bottom" title="' +
((__t = ( copyButton )) == null ? '' : __t) +
'">\n                                        <div class="copyTextIcon"></div>\n                                    </button>\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class="row stepRow" id="step6row">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 text-center stepTd"><strong>' +
((__t = ( step6 )) == null ? '' : __t) +
'</strong></div>\n                    <div class="col-sm-8 add_margin_10 stepTd">' +
((__t = ( step6Text )) == null ? '' : __t) +
'</div>\n                </div>\n                <div class="row stepRow" id="step6PermissionsRow">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 stepTd">&nbsp;</div>\n                    <div class="col-sm-6 add_margin_10 stepTd">\n                        <div id="permissions" class="form-group">\n\n                        </div>\n                    </div>\n                </div>\n                <div class="row stepRow" id="step6brow">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 stepTd"></div>\n                    <div class="col-sm-8 add_margin_10 stepTd">' +
((__t = ( step6bText )) == null ? '' : __t) +
'</div>\n                </div>\n\n                <div class="row stepRow" id="step8row">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 text-center stepTd"><strong>' +
((__t = ( step8 )) == null ? '' : __t) +
'</strong></div>\n                    <div class="col-sm-8 add_margin_10 stepTd">' +
((__t = ( step8Text )) == null ? '' : __t) +
'</div>\n                </div>\n\n                <div class="row stepRow" id="step8row">\n                    <div class="col-sm-2 text-center stepTdSpacer"></div>\n                    <div class="col-sm-2 text-center stepTd"><strong>' +
((__t = ( step9 )) == null ? '' : __t) +
'</strong></div>\n                    <div class="col-sm-8 add_margin_10 stepTd">' +
((__t = ( step9Text )) == null ? '' : __t) +
' ' +
((__t = ( step9bText )) == null ? '' : __t) +
'</div>\n                </div>\n          </div>\n\n          <div class="row" id="step10brow">\n            <div class="col-sm-2 text-center"><strong>' +
((__t = ( step10 )) == null ? '' : __t) +
'</strong></div>\n            <div class="col-sm-10 add_margin_10">' +
((__t = ( step10Text )) == null ? '' : __t) +
'</div>\n          </div>\n\n          <div class="row" id="roleArnRow">\n              <div class="col-sm-2"></div>\n              <div class="col-sm-8 add_margin_10">\n                  <div class="form-group  has-feedback">\n                    <div class="input-group mb-2 mt-2">\n                      <div class="input-group-text" id="roleArnLabel">' +
((__t = ( roleArn )) == null ? '' : __t) +
'</div>\n                      <input class="form-control" id="role_arn" name="role_arn" type="text" autocomplete="off">\n                    </div>\n                  </div>\n              </div>\n          </div>\n\n          <div class="row" id="step11row">\n              <div class="col-sm-2 text-center"><strong>' +
((__t = ( step11 )) == null ? '' : __t) +
'</strong></div>\n              <div class="col-sm-10 add_margin_10">' +
((__t = ( step11Text )) == null ? '' : __t) +
'</div>\n          </div>\n\n          <div class="row" id="descRow">\n              <div class="col-sm-2 text-center"></div>\n              <div class="col-sm-8 add_margin_10">\n                  <div class="form-group has-feedback">\n                      <div class="input-group mb-2 mt-2">\n                          <div class="input-group-text" id="descLabel">' +
((__t = ( desp )) == null ? '' : __t) +
'</div>\n                          <input class="form-control" id="description" name="description" type="text" value="" maxlength="75">\n                      </div>\n                  </div>\n              </div>\n          </div>\n          <p>' +
((__t = ( addCredsFooterNote )) == null ? '' : __t) +
'\n              <a id="awsDocLink" target="_blank" href="https://www.mathworks.com/help/cloudcenter/ug/aws-identity-and-access-management-iam.html" rel="noopener noreferrer">\n                  ' +
((__t = ( awsIamText )) == null ? '' : __t) +
'\n              </a>.\n          </p>\n          </div>\n\n          <div id="dialogFoot" class="dialogFoot">\n            <button class="btn btn_color_blue companion_btn" id="closeCreateDialog" type="button">' +
((__t = ( cancelButtonText )) == null ? '' : __t) +
'</button>\n            <button class="btn btn_color_blue add_indent_10" id="importAwsCredsButton" disabled=\'disabled\' type="submit">' +
((__t = ( nextBtnText )) == null ? '' : __t) +
'</button>\n          </div>\n      </form>\n\n\n\n\n  </div>\n\n</div>\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
