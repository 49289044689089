module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<!-- config summary -->\n<div id="configInfoContainer" class="configInfoContainer">\n  <div class="configInfoHeader">\n    <span class="horizontalIconContainer">\n      <h2>' +
((__t = ( configSummaryTitle )) == null ? '' : __t) +
'</h2>\n    </span>\n    <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeConfigButton"></button>\n  </div>\n  <div class="configInfoBox" style="display:none;">\n    <div class="configInfoTitle">\n      <span class="icon-info-circle add_icon_color_linkblue"></span>\n      <h2 id="configInfoText">' +
((__t = ( lostFeaturesText )) == null ? '' : __t) +
'</h2>\n    </div>\n    <ul id="lostFeatureList" class="lostFeatureList"></ul>\n  </div>\n  <div class="matlabConfigurationBox">\n    <h1>' +
((__t = ( matlabConfigTitle )) == null ? '' : __t) +
'</h1>\n    <ul></ul>\n  </div>\n</div>\n';

}
return __p
};
