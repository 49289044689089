module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (availableProducts && availableProducts.cloud_network) { ;
__p += '\n  <div id="resourceContainer" class="resourceContainer">\n    <div id="CloudNetworkBar" class="resourceTitleBar">\n      <div class="resourceContainerLabel"><span>' +
((__t = ( i18n.computeResourcePageCloudNetworkLabel )) == null ? '' : __t) +
'</span></div>\n      <div class="dropdown menu-container createNewComputeResource">\n        <button id="startNewCloudNetworkButton" type="button" disabled="disabled" class="disabled btn startNewMatlabButton" title="' +
((__t = ( i18n.createCloudNetworkButtonTooltip )) == null ? '' : __t) +
'" aria-haspopup="true" aria-expanded="false">\n          <span class="icon-add"></span>\n          <span>' +
((__t = ( i18n.computeResourcePageCreateNewCloudNetworkLabel )) == null ? '' : __t) +
'</span>\n        </button>\n      </div>\n    </div>\n    <div class="noCloudNetworkMessageContainer" style="display: none;">\n      <div class="noDataMsg"><span>' +
((__t = ( i18n.computeResourcePageNoCloudNetworkFound )) == null ? '' : __t) +
'</span></div>\n    </div>\n    <div class="cloudNetworkContainer">\n      <section>\n        <ol id="cloud_network-resource-collection" class="collection collection-container">\n          <div class="progressSpinnerContainer">\n            <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n          </div>\n        </ol>\n      </section>\n    </div>\n  </div>\n';
 } ;
__p += '\n';

}
return __p
};
