module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="aboutViewHeader" class="headerContainer">\n\n  ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n</div>\n\n\n<div id="aboutPageView" class="pageView">\n\n  <div class="facetBar" id="aboutPageFacetPicker">\n    <ul class="facets">\n      <li><a id="resourceLink" href="javascript:void(0)">' +
((__t = ( i18n.computeLinkText )) == null ? '' : __t) +
'</a></li>\n      <li><a id="credentialLink" href="javascript:void(0)">' +
((__t = ( i18n.credentialLinkText )) == null ? '' : __t) +
'</a></li>\n    </ul>\n  </div>\n\n  <div id="aboutContainer1" class="aboutContainer">\n\n    <div id="aboutResourceContainer" class="resourceContainer">\n      <div id="aboutResourcesBar" class="resourceTitleBar">\n        <div class="resourceContainerLabel">\n          <span>' +
((__t = ( i18n.aboutAvailableProducts )) == null ? '' : __t) +
'</span>\n        </div>\n       </div>\n\n      <div class="aboutInstanceContainer" style="display: block;">\n        <ol id="tandcList2" class="collection collection-container">\n          <li class="item item-container item-container-for-about-page about-list-header">\n            <div class="attribute-container about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutDescription )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutDescription )) == null ? '' : __t) +
':">' +
((__t = ( i18n.aboutDescription )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="attribute-container about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutProviderName )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutProviderName )) == null ? '' : __t) +
':">' +
((__t = ( i18n.aboutProviderName )) == null ? '' : __t) +
'</div>\n            </div>\n          </li>\n        </ol>\n      </div>\n\n    </div>\n\n  </div>\n\n  ';
 if (azure) { ;
__p += '\n  <div id="aboutContainer2" class="aboutContainer">\n\n    <div id="aboutResourceContainer" class="resourceContainer">\n      <div id="aboutResourcesBar" class="resourceTitleBar">\n        <div class="resourceContainerLabel">\n          <span>' +
((__t = ( i18n.aboutThirdPartyLicenseTerms )) == null ? '' : __t) +
'</span>\n        </div>\n       </div>\n\n      <div class="aboutInstanceContainer" style="display: block;">\n        <ol id="tandcList" class="collection collection-container">\n          <li class="item item-container item-container-for-about-page about-list-header">\n            <div class="attribute-container margin-mobile about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutProvider )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutProvider )) == null ? '' : __t) +
':">' +
((__t = ( i18n.aboutProvider )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="attribute-container about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutService )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutService )) == null ? '' : __t) +
':">' +
((__t = ( i18n.aboutService )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="attribute-container about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutLink )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutLink )) == null ? '' : __t) +
':">' +
((__t = ( i18n.aboutLink )) == null ? '' : __t) +
'</div>\n            </div>\n\n          </li>\n        <li class="item item-container item-container-for-about-page">\n            <div class="attribute-container margin-mobile about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutProvider )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutProvider )) == null ? '' : __t) +
':">Microsoft</div>\n            </div>\n            <div class="attribute-container about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutService )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutService )) == null ? '' : __t) +
':">Azure</div>\n            </div>\n            <div class="attribute-container about-page-font-mobile">\n              <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutLink )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutLink )) == null ? '' : __t) +
':"><a href="https://mpcprodsa.blob.core.windows.net/marketplaceterms/3EDEF_marketplaceterms_VIRTUALMACHINE%253a24AAK2OAIZEAWW5H4MSP5KSTVB6NDKKRTUBAU23BRFTWN4YC2MQLJUB5ZEYUOUJBVF3YK34CIVPZL2HWYASPGDUY5O2FWEGRBYOXWZE5Y.txt" target="_blank">Marketplace Terms</a></div>\n            </div>\n\n          </li></ol>\n      </div>\n    </div>\n\n  </div>\n  ';
 } ;
__p += '\n\n</div>\n\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
