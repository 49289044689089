module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="managementViewHeader" class="headerContainer">\n\n  ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n</div>\n\n\n<div id="managementPageView" class="pageView">\n\n  <div class="facetBar" id="managementPageFacetPicker">\n    <ul class="facets">\n      <li><a id="resourceLink" href="javascript:void(0)">' +
((__t = ( i18n.computeLinkText )) == null ? '' : __t) +
'</a></li>\n      <li><a id="credentialLink" href="javascript:void(0)">' +
((__t = ( i18n.credentialLinkText )) == null ? '' : __t) +
'</a></li>\n    </ul>\n  </div>\n\n  <div id="managementContainer1" class="managementContainer">\n\n    <div id="managementResourceContainer" class="resourceContainer">\n      <div id="managementResourcesBar" class="resourceTitleBar">\n        <div class="resourceContainerLabel">\n          <span>' +
((__t = ( i18n.managementCloudAccounts )) == null ? '' : __t) +
'</span>\n        </div>\n       </div> \n  \n\n      <div class="managementInstanceContainer" id="cloudAccountList" style="display: block;">  \n        <div class="">' +
((__t = ( i18n.managementLoadingAccounts )) == null ? '' : __t) +
'</div>\n        <!-- <ol id="tandcList" class="collection collection-container">\n          <li class="item item-container item-container-for-management-page management-list-header">\n            <div class="attribute-container management-page-font-mobile">\n              <div class="attribute attribute-management-page" data-name="' +
((__t = ( i18n.managementAccountName )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.managementAccountName )) == null ? '' : __t) +
':">\n                  ' +
((__t = ( i18n.managementAccountName )) == null ? '' : __t) +
'\n              </div>\n            </div>\n            <div class="attribute-container management-page-font-mobile">\n              <div class="attribute attribute-management-page" data-name="' +
((__t = ( i18n.managementAccountId )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.managementAccountId )) == null ? '' : __t) +
':">\n                  ' +
((__t = ( i18n.managementAccountId )) == null ? '' : __t) +
'\n              </div>\n            </div>\n            <div class="attribute-container management-page-font-mobile">\n              <div class="attribute attribute-management-page" data-name="' +
((__t = ( i18n.managementProviderName )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.managementProviderName )) == null ? '' : __t) +
':">' +
((__t = ( i18n.managementProviderName )) == null ? '' : __t) +
'</div>\n            </div>\n          </li>\n\n            <li class="item item-container item-container-for-management-page"> \n              <div class="attribute-container management-page-font-mobile">\n                <div class="attribute attribute-management-page" data-name="Description" data-mobile-name="Description:"> MATLAB R2023b</div>\n              </div>\n              <div class="attribute-container management-page-font-mobile">\n                <div class="attribute attribute-management-page" data-name="Description" data-mobile-name="Description:"> MATLAB R2023b</div>\n              </div>\n              <div class="attribute-container management-page-font-mobile">\n                <div class="attribute attribute-management-page" data-name="Provider (Operating System)" data-mobile-name="Provider (Operating System):">Amazon AWS (Linux, Windows)';
 if (true) { ;
__p += ', Microsoft Azure (Linux, Windows)';
 } ;
__p += '</div>\n              </div>\n            </li>\n                \n          \n        </ol> -->\n      </div>\n    </div>\n\n  </div>\n\n</div>\n\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
