module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=' +
((__t = ( classList )) == null ? '' : __t) +
' tabindex="-1">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title">' +
__e( warningHeader ) +
'</h4>\n                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body delete-item-body-prompt">\n              <span>\n                ';
 if (!hideIcon) { ;
__p += '\n                <div class="warningIcon"></div>\n                ';
 } ;
__p += '\n                <p class="warningDialogText">' +
((__t = ( warningText )) == null ? '' : __t) +
'</p>\n                ';
 if (showProceedPrompt) { ;
__p += '\n                <p class="warningDialogText">' +
((__t = ( proceedPrompt )) == null ? '' : __t) +
'</p>\n                ';
 } ;
__p += '\n              </span>\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="warningCloseButton" class="btn btn_color_blue companion_btn closeWarningBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( closeButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" id="warningActionButton" class="btn btn_color_blue add_indent_10 actionWarningBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( actionButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};
