module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="resourceContainer" class="resourceContainer">\n  <div id="resourcesBar" class="resourceTitleBar">\n    <div class="resourceContainerLabel"><span>' +
((__t = ( i18n.computeResourcePageResourcesLabel )) == null ? '' : __t) +
'</span></div>\n      <button id="startNewMatlabButton" title="' +
((__t = ( i18n.createMatlabButtonTooltip )) == null ? '' : __t) +
'" type="button" disabled="disabled" class="disabled btn btn_color_blue companion_btn startNewMatlabButton" aria-haspopup="true" aria-expanded="false">\n        <span class="icon-add"></span>\n        <span>' +
((__t = ( i18n.computeResourcePageCreateNewMatlabLabel )) == null ? '' : __t) +
'</span>\n      </button>\n    </div>\n  <div class="noMatlabMessageContainer" style="display: none;">\n    <div class="noDataMsg"><span>' +
((__t = ( i18n.computeResourcePageNoResourcesFound )) == null ? '' : __t) +
'</span></div>\n  </div>\n  <div class="matlabContainer">\n    <section>\n      <ol id="matlab-resource-collection" class="collection collection-container">\n          <div class="progressSpinnerContainer">\n            <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n          </div>\n        </ol>\n    </section>\n  </div>\n</div>\n';

}
return __p
};
