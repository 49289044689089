module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="credentialsViewHeader" class="headerContainer">\n\n  ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n</div>\n\n<div id="credentialsPageView" class="pageView">\n\n  <div class="facetBar" id="credentialsPageFacetPicker">\n    <ul class="facets">\n      <li><a id="resourceLink" href="javascript:void(0)">' +
((__t = ( computeLinkText )) == null ? '' : __t) +
'</a></li>\n      <li>' +
((__t = ( credentialLinkText )) == null ? '' : __t) +
'</li>\n    </ul>\n  </div>\n\n  <div id="credentialsContainer" class="credentialsContainer">\n\n    <div id="credentialsResourceContainer" class="resourceContainer">\n      <div id="credentialResourcesBar" class="resourceTitleBar">\n        <div class="resourceContainerLabel">\n          <span>' +
((__t = ( credentialsResourcesLabel )) == null ? '' : __t) +
'</span>\n        </div>\n        <button id="addCredentialsButton" type="button" class="btn btn_color_blue companion_btn startNewMatlabButton" aria-haspopup="true" aria-expanded="false">\n          <span class="icon-add"></span>\n          <span>' +
((__t = ( createCredential )) == null ? '' : __t) +
'</span>\n        </button>\n      </div>\n      <div class="noMatlabMessageContainer" style="display: none;">\n        <div class="noDataMsg"><span>' +
((__t = ( noResourcesFoundMessage )) == null ? '' : __t) +
'</span></div>\n      </div>\n      <div class="credentialInstanceContainer">\n        <ol id="credentialInstanceList" class="collection collection-container">\n          <li class="item item-container item-container-for-cred-page credential-list-header">\n            <input type="hidden"  />\n            <div class="attribute-container cred-page-title-mobile">\n              <div class="attribute attribute-credential-page" data-name="' +
((__t = ( credName )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( credName )) == null ? '' : __t) +
':">' +
((__t = ( credName )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="attribute-container margin-mobile cred-page-font-mobile">\n              <div class="attribute attribute-credential-page" data-name="' +
((__t = ( credAccountIdentifier )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( credAccountIdentifier )) == null ? '' : __t) +
':">' +
((__t = ( credAccountIdentifier )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="attribute-container cred-page-font-mobile">\n              <div class="attribute attribute-credential-page" data-name="' +
((__t = ( credPlatform )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( credPlatform )) == null ? '' : __t) +
':">' +
((__t = ( credPlatform )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="attribute-container cred-page-font-mobile">\n              <div class="attribute attribute-credential-page" data-name="' +
((__t = ( credCredentialType )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( credCredentialType )) == null ? '' : __t) +
':">' +
((__t = ( credCredentialType )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="credentialButtonsContainer attribute">\n              ';
 if (!useEditableText) { ;
__p += '\n              <button id="credEditButton" type="button" title="' +
((__t = ( credEditLabel )) == null ? '' : __t) +
'" aria-label="' +
((__t = ( credEditLabel )) == null ? '' : __t) +
'" tabindex="0" class="btn btn_color_blue companion_btn btn-sm icon-attribute cta-btn" style="display: none;">\n                <span class="visually-hidden">' +
((__t = ( credEditLabel )) == null ? '' : __t) +
'</span>\n                <div class="editCredentialIcon smallIcon"></div>\n              </button>\n              ';
 } ;
__p += '\n              <button id="credDeleteButton" type="button" title="' +
((__t = ( credDeleteLabel )) == null ? '' : __t) +
'" aria-label="' +
((__t = ( credDeleteLabel )) == null ? '' : __t) +
'" tabindex="0" class="btn btn_color_blue companion_btn btn-sm icon-attribute cta-btn" style="display: none;">\n                <span class="visually-hidden">' +
((__t = ( credDeleteLabel )) == null ? '' : __t) +
'</span>\n                <div class="deleteCredentialIcon smallIcon"></div>\n              </button>\n              <button id="unshareButton" type="button" title="' +
((__t = ( credUnshareLabel )) == null ? '' : __t) +
'" aria-label="' +
((__t = ( credUnshareLabel )) == null ? '' : __t) +
'" tabindex="0" class="btn btn_color_blue companion_btn btn-sm icon-attribute cta-btn" style="display: none;">\n                <span class="visually-hidden">' +
((__t = ( credUnshareLabel )) == null ? '' : __t) +
'</span>\n                <div class="deleteCredentialIcon smallIcon"></div>\n              </button>\n            </div>\n          </li>\n        </ol>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
