module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (listItems && Array.isArray(listItems) && listItems.length) { ;
__p += '\n  <div class="additionalDetailHeader">\n    <span class="horizontalIconContainer">\n      <a class="sectionExpander">\n        <span ';
 if (sectionExpanded) { ;
__p += 'class="icon-arrow-open-down"';
 } else { ;
__p += 'class="icon-arrow-open-right"';
 } ;
__p += '></span>\n        <h2 class="subtitle">' +
((__t = ( sectionTitle )) == null ? '' : __t) +
'</h2>\n      </a>\n    </span>\n  </div>\n  <dl ';
 if (sectionExpanded) { ;
__p += 'class="row sectionContents"';
 } else { ;
__p += 'class="row sectionContents collapsed"';
 } ;
__p += '>\n    ';
 for (let item of listItems) { ;
__p += '\n      ';
 if (item.subListData && typeof item.subListData === "object" && Object.keys(item.subListData).length) { ;
__p += '\n        <dt class="col-sm-3 vpcSubnet">' +
((__t = ( item.label )) == null ? '' : __t) +
':</dt><dd class="col-sm-9 vpcSubnet">' +
((__t = ( _.escape(item.value) )) == null ? '' : __t) +
'</dd>\n          <dd class="detailsSubSectionList">\n            <dl class="row">\n              ';
 for (let key of Object.keys(item.subListData)) { ;
__p += '\n                <dt class="col-sm-3">' +
((__t = ( key )) == null ? '' : __t) +
':</dt><dd class=col-sm-9>' +
((__t = ( _.escape(item.subListData[key]) )) == null ? '' : __t) +
'</dd>\n              ';
 } ;
__p += '\n            </dl>\n          </dd>\n        </dt>\n      ';
 } else { ;
__p += '\n        ';
 if (item.type === "password") { ;
__p += '\n        <dt class="col-sm-3">' +
((__t = ( item.label )) == null ? '' : __t) +
':</dt><dd class="col-sm-9"><form action="javascript:void(0);"><label class="visually-hidden" for="password">' +
((__t = ( item.label )) == null ? '' : __t) +
'</label><input type="password" name="password" id="password" autocomplete="off" class="detailsListItemValue password" value=' +
((__t = ( item.value )) == null ? '' : __t) +
' readonly /><button type="button" class="btn btn_color_blue companion_btn viewPassword" title="' +
((__t = ( showOrHidePassword )) == null ? '' : __t) +
'"><span class="visually-hidden">' +
((__t = ( showOrHidePassword )) == null ? '' : __t) +
'</span><div class="viewPasswordIcon smallIcon"></div></button></form></dd>\n        ';
 } else { ;
__p += '\n          <dt class="col-sm-3">' +
((__t = ( item.label )) == null ? '' : __t) +
':</dt><dd class="col-sm-9">' +
((__t = ( item.value )) == null ? '' : __t) +
'</dd>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    ';
 } ;
__p += '\n  </dl>\n';
 } else { ;
__p += '\n  <h2 class="subtitle">' +
((__t = ( sectionTitle )) == null ? '' : __t) +
'</h2>\n';
 } ;
__p += '\n';

}
return __p
};
