module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="additionalDetailHeader">\n  <span class="horizontalIconContainer">\n    <a class="sectionExpander">\n      <span class="icon-arrow-open-right"></span>\n      <h2 id="firewallAccessHeader" class="subtitle">' +
((__t = ( i18n.computeDetailsAccessListTitle )) == null ? '' : __t) +
'</h2>\n    </a>\n  </span>\n</div>\n<div class="sectionContents collapsed">\n  <div class="IPOuterContainer">\n    <div id="IPContainer" class="IPContainer">\n      <span class="ipLabel">' +
((__t = ( i18n.computeResourceDetailPageIPLabel )) == null ? '' : __t) +
'</span>\n      <span class="ipValue"></span>\n      <button id="copyIPValueButton" class="btn companion_btn copyIPValueButton" type="button"><span class="visually-hidden">' +
((__t = ( i18n.copyIPValueButtonTooltip )) == null ? '' : __t) +
'</span><div class="copyTextIcon" title="' +
((__t = ( i18n.copyIPValueButtonTooltip )) == null ? '' : __t) +
'"></div></button>\n    </div>\n  </div>\n  <div id="firewallAccessContainer" class="accessList">\n  ';
 if (accessData && accessData.inbound_permission) { ;
__p += '\n    ';
 for (let item of accessData.inbound_permission) { ;
__p += '\n      <div class="accessPortContainer">\n        <h3 class="subtitle">\n          <button type="button" data-ip="' +
((__t = ( item.ip )) == null ? '' : __t) +
'" data-from-port="' +
((__t = ( item.from_port )) == null ? '' : __t) +
'" data-to-port="' +
((__t = ( item.to_port )) == null ? '' : __t) +
'" data-protocol="' +
((__t = ( item.protocol )) == null ? '' : __t) +
'" class="removeAccessButton btn btn_color_blue companion_btn">' +
((__t = ( i18n.computeDetailsLabelDeleteAccess )) == null ? '' : __t) +
'</button>\n          <div class="progressSpinnerContainer" style="display:none;"><mw-progress-indicator type="spinner" size="small"></mw-progress-indicator></div>\n        </h3>\n        <ul class="detailsSubSectionList">\n          <li><div class="detailsListItemLabel">' +
((__t = ( i18n.computeDetailsLabelAccessIP )) == null ? '' : __t) +
'</div><div class="detailsListItemValue">' +
((__t = ( item.ip )) == null ? '' : __t) +
'</div></li>\n          <li><div class="detailsListItemLabel">' +
((__t = ( i18n.computeDetailsLabelAccessDescription )) == null ? '' : __t) +
'</div><div class="detailsListItemValue">' +
((__t = ( item.description )) == null ? '' : __t) +
'</div></li>\n          <li><div class="detailsListItemLabel">' +
((__t = ( i18n.computeDetailsLabelAccessProtocol )) == null ? '' : __t) +
'</div><div class="detailsListItemValue">' +
((__t = ( item.protocol )) == null ? '' : __t) +
'</div></li>\n          ';
 if (item.from_port === item.to_port) { ;
__p += '\n            <li><div class="detailsListItemLabel">' +
((__t = ( i18n.computeDetailsLabelAccessFromPort )) == null ? '' : __t) +
'</div><div class="detailsListItemValue">' +
((__t = ( item.from_port )) == null ? '' : __t) +
'</div></li>\n          ';
 } else { ;
__p += '\n          <li><div class="detailsListItemLabel">' +
((__t = ( i18n.computeDetailsLabelAccessFromPortRange )) == null ? '' : __t) +
'</div><div class="detailsListItemValue">' +
((__t = ( item.from_port )) == null ? '' : __t) +
'&nbsp;&#8209;&nbsp;' +
((__t = ( item.to_port )) == null ? '' : __t) +
'</div></li>\n          ';
 } ;
__p += '\n        </ul>\n      </div>\n    ';
 } ;
__p += '\n  ';
 } ;
__p += '\n  <button class="addAccessButton btn btn_color_blue companion_btn">' +
((__t = ( i18n.computeDetailsLabelAddNewAccess )) == null ? '' : __t) +
'</button>\n  </div>\n</div>\n';

}
return __p
};
