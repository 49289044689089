module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (outputData) { ;
__p += '\n<div class="additionalDetailHeader">\n  <span class="horizontalIconContainer">\n    <a class="sectionExpander">\n      <span class="icon-arrow-open-right"></span>\n      <h2 id="outputsHeader" class="subtitle">' +
((__t = ( i18n.computeDetailsOutputListTitle )) == null ? '' : __t) +
'</h2>\n    </a>\n  </span>\n</div>\n<div class="sectionContents collapsed">\n  <div id="outputsContainer" class="outputsContainer accessList">\n    ';
 for (let item of outputData)  { ;
__p += '\n      <div class="outputContainer">\n        <dl class="row">\n          <dt class="col-sm-3 outputsLabel">' +
((__t = ( item.description )) == null ? '' : __t) +
'</dt>\n          <dd class="col-sm-9 outputsValue">\n            ' +
((__t = ( item.value )) == null ? '' : __t) +
'\n            <button class="btn btn_color_blue companion_btn copyOutputValueButton" type="button" data-url="' +
((__t = ( item.value )) == null ? '' : __t) +
'" title="' +
((__t = ( i18n.copyOutputValueButtonTooltip )) == null ? '' : __t) +
'"><span class="visually-hidden">' +
((__t = ( i18n.copyOutputValueButtonTooltip )) == null ? '' : __t) +
'</span><div class="copyTextIcon smallIcon"></div></button>\n          </dd>\n        </dl>\n        <ul class="detailsSubSectionList">\n          ';
 if (item.export_name) { ;
__p += '\n            <li><div class="detailsListItemLabel">' +
((__t = ( i18n.computeDetailsLabelOutputExportName )) == null ? '' : __t) +
'</div><div class="detailsListItemValue">' +
((__t = ( item.export_name )) == null ? '' : __t) +
'</div></li>\n          ';
 } ;
__p += '\n        </ul>\n      </div>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};
