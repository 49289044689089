module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="computeViewHeader" class="headerContainer">\n\n  ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n</div>\n\n<div id="computeResourcePageView" class="pageView">\n  <div class="facetBar" id="computeResourcePageFacetPicker">\n    <ul class="facets"><li>' +
((__t = ( i18n.computeLinkText )) == null ? '' : __t) +
'</li>\n      <li><a id="credentialLink" href="javascript:void(0)">' +
((__t = ( i18n.credentialLinkText )) == null ? '' : __t) +
'</a></li>\n    </ul>\n  </div>\n\n  <div id="createWizardContainer" class="wizardContainer" style="display:none;"></div>\n\n  <div id="computeContainer" class="computeContainer">\n    ' +
((__t = ( productsHTML )) == null ? '' : __t) +
'\n  </div>\n</div>\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
