module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="wizardCredTypeDialog" class="modal fade"tabindex="-1">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title">' +
__e( wizardCredTypeHeader ) +
'</h4>\n                <button id="credTypeCloseWizardButton" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body delete-item-body-prompt">\n                <h6 class="modal-body-heading">' +
((__t = ( credTypeHeading )) == null ? '' : __t) +
'</h6>\n                <ul class="cred-type-dialog" id="credential_nav">\n                    <li >' +
((__t = ( createAWSCredential )) == null ? '' : __t) +
'</li>\n                    <li class="cred-type-option">\n                      <a href="javascript:void(0);" class="cred-action" id="importAWSRoleCredential" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( importAWSRoleCredential )) == null ? '' : __t) +
'</a>\n                    </li>\n                    <li class="cred-type-option">\n                      <a href="javascript:void(0);" class="cred-action" id="createAWSRoleCredential" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( createAWSRoleCredential )) == null ? '' : __t) +
'</a>\n                    </li>\n                  </ul>\n                <ul class="cred-type-dialog" id="credential_nav">\n                ';
 if (!azureSupported) { ;
__p += '\n                    <li >' +
((__t = ( createAzureComingSoon )) == null ? '' : __t) +
'</li>\n                ';
 } else { ;
__p += '\n                    <li >' +
((__t = ( createAzureCredential )) == null ? '' : __t) +
'</li>\n                    <li class="cred-type-option">\n                        <a href="javascript:void(0);" class="cred-action" id="createAzureRefreshTokenCredential" data-dismiss="modal" tabindex="0">' +
((__t = ( createAzureRefreshTokenCredential )) == null ? '' : __t) +
'</a>\n                    </li>\n                ';
 } ;
__p += '\n                </ul>\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="credTypeCancelWizardButton" class="btn btn_color_blue cancelWizardBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( cancelWizardButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};
