module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- /* //NOSONAR */ --><li class="item item-container " data-updating="false">\n  <div class="attribute resource-machine-name" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderName )) == null ? '' : __t) +
'"><a href="#" class="goToResourceDetail" data-bs-toggle="tooltip" data-bs-placement="top" title="' +
((__t = ( params.name )) == null ? '' : __t) +
'" data-id="' +
((__t = ( params.id )) == null ? '' : __t) +
'">' +
((__t = ( params.name )) == null ? '' : __t) +
'</a></div>\n  <div class="attribute resource-release" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderRelease )) == null ? '' : __t) +
'">' +
((__t = ( params.product )) == null ? '' : __t) +
'</div>\n  <div class="attribute hide-mobile-header resource-ip-address ip-address hide" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderIPAddress )) == null ? '' : __t) +
'"><div class="progressSpinnerContainer"><mw-progress-indicator type="spinner" size="small"></mw-progress-indicator></div></div>\n  <div class="attribute resource-operating-system" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderOperatingSystem )) == null ? '' : __t) +
'">' +
((__t = ( params.operating_system )) == null ? '' : __t) +
'</div>\n  <div class="attribute resource-cloud-provider" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderCloudProvider )) == null ? '' : __t) +
'" title="' +
((__t = ( params.cloud_provider )) == null ? '' : __t) +
' ';
 if (params.cloud_account) { ;
__p += '(' +
((__t = ( params.cloud_account )) == null ? '' : __t) +
')';
 } ;
__p += '">' +
((__t = ( params.cloud_provider )) == null ? '' : __t) +
' ';
 if (params.cloud_account) { ;
__p += '(' +
((__t = ( params.cloud_account )) == null ? '' : __t) +
')';
 } ;
__p += '</div>\n  <div class="attribute resource-cloud-location hide" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderCloudLocation )) == null ? '' : __t) +
'">' +
((__t = ( params.cloud_location )) == null ? '' : __t) +
'</div>\n  <div class="attribute resource-status status" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderStatus )) == null ? '' : __t) +
'"><div class="progressSpinnerContainer"><mw-progress-indicator type="spinner" size="small"></mw-progress-indicator></div></div>\n  <div class="attribute hide-mobile-header resource-primary-cta cta icon-container hide" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderActions )) == null ? '' : __t) +
'"><div class="progressSpinnerContainer"><mw-progress-indicator type="spinner" size="small"></mw-progress-indicator></div></div>\n  <div class="attribute hide-mobile-header resource-menu context-menu menu-container hide" data-name=""><div class="progressSpinnerContainer"><mw-progress-indicator type="spinner" size="small"></mw-progress-indicator></div></div>\n</li>\n';

}
return __p
};
