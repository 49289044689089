module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (availableProducts && availableProducts.production_server) { ;
__p += '\n  <div id="resourceContainer" class="resourceContainer">\n    <div id="productionServerBar" class="resourceTitleBar">\n      <div class="resourceContainerLabel"><span>' +
((__t = ( i18n.computeResourcePageProductionServerLabel )) == null ? '' : __t) +
'</span></div>\n      <div class="dropdown menu-container createNewComputeResource">\n        <button id="startNewProductionServerButton" title="' +
((__t = ( i18n.createProductionServerButtonTooltip )) == null ? '' : __t) +
'" type="button" disabled="disabled" class="disabled btn startNewMatlabButton" aria-haspopup="true" aria-expanded="false">\n          <span class="icon-add"></span>\n          <span>' +
((__t = ( i18n.computeResourcePageCreateNewProductionServerLabel )) == null ? '' : __t) +
'</span>\n        </button>\n      </div>\n    </div>\n    <div class="noProductionServerMessageContainer" style="display: none;">\n      <div class="noDataMsg"><span>' +
((__t = ( i18n.computeResourcePageNoProductionServersFound )) == null ? '' : __t) +
'</span></div>\n    </div>\n    <div class="productionServerContainer">\n      <section>\n        <ol id="production_server-resource-collection" class="collection collection-container">\n          <div class="progressSpinnerContainer">\n            <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n          </div>\n        </ol>\n      </section>\n    </div>\n  </div>\n';
 } ;
__p += '\n';

}
return __p
};
