module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form name="editConfigForm" class="editConfigForm">\n  <div class="wizardSection">\n    <label class="stepLegend">Edit Configuration</label>\n    <div id="releaseOsRegion" class="collapsibleSection">\n      <div id="nameContainer" class="stepComponentContainer edit">\n        <label for="mw-name">' +
((__t = ( i18n.editFormLabelName )) == null ? '' : __t) +
'</label>\n        <p class="finePrint">' +
((__t = ( i18n.cmlWizardNameFinePrint )) == null ? '' : __t) +
'</p>\n        <input type="text" name="mw-name" id="mw-name" maxlength="' +
((__t = ( i18n.cmlWizardNameMaxLength )) == null ? '' : __t) +
'" value=' +
((__t = ( "\"" + _.escape(data["mw-name"]) + "\"" )) == null ? '' : __t) +
' />\n        <div class="inline_form_error" style="display: none;">\n          <div class="inline_form_error_arrow"></div>\n          <div class="inline_form_error_msg"></div>\n        </div>\n        <div class="validationErrorImage form-control-feedback fineprint" aria-hidden="true" style="display: none;"></div>\n      </div>\n      <ul id="nameProductList" class="detailsSubSectionList disabled">\n        ';
 if (data.releaseDisplayText !== "none") { ;
__p += '\n        <li>\n          <div class="detailsListItemLabel">' +
((__t = ( i18n.editFormLabelRelease )) == null ? '' : __t) +
'</div>\n          <input type="text" id="release" name="release" disabled class="disabled" value="' +
((__t = ( _.escape(data.releaseDisplayText) )) == null ? '' : __t) +
'" />\n          ';
 if (data.eol && data.eol.Status === 2) { ;
__p += '\n            <button type="button" class="warning-notification deprecated"\n              data-status="Deprecated"\n              data-release="' +
((__t = ( _.escape(data.releaseDisplayText) )) == null ? '' : __t) +
'"\n              data-deprecateDate="' +
((__t = ( Util.formatDateYMD(new Date(data.eol.DeprecateOnDate.seconds * 1000)) )) == null ? '' : __t) +
'"\n              data-sunsetDate="' +
((__t = ( Util.formatDateYMD(new Date(data.eol.SunsetOnDate.seconds * 1000)) )) == null ? '' : __t) +
'"\n            >\n              <span class="icon-alert-warning" title="' +
((__t = ( DojoString.substitute(i18n.deprecatedTooltip, [Util.formatDateYMD(new Date(data.eol.SunsetOnDate.seconds * 1000))]) )) == null ? '' : __t) +
'"></span>\n            </button>\n          ';
 } else if (data.eol && data.eol.Status === 3)  { ;
__p += '\n            <button type="button" class="warning-notification sunsetted"\n              data-status="Sunsetted"\n              data-release="' +
((__t = ( _.escape(data.releaseDisplayText) )) == null ? '' : __t) +
'"\n              data-deprecateDate="' +
((__t = ( Util.formatDateYMD(new Date(data.eol.DeprecateOnDate.seconds * 1000)) )) == null ? '' : __t) +
'"\n              data-sunsetDate="' +
((__t = ( Util.formatDateYMD(new Date(data.eol.SunsetOnDate.seconds * 1000)) )) == null ? '' : __t) +
'"\n            >\n              <span class="icon-alert-warning" title="' +
((__t = ( DojoString.substitute(i18n.sunsettedTooltip, [Util.formatDateYMD(new Date(data.eol.SunsetOnDate.seconds * 1000))]) )) == null ? '' : __t) +
'"></span>\n            </button>\n          ';
 } ;
__p += '\n        </li>\n        ';
 } ;
__p += '\n        ';
 if (data['cloud_provider'] !== "none") { ;
__p += '\n        <li><div class="detailsListItemLabel">' +
((__t = ( i18n.editFormLabelCloudPlatform )) == null ? '' : __t) +
'</div><input type="text" id="provider" name="provider" disabled class="disabled" value="' +
((__t = ( _.escape(formatter.getDisplayValue('cloudPlatform', data['cloud_provider'])) )) == null ? '' : __t) +
'" /></li>\n        ';
 } ;
__p += '\n        ';
 if (data.cloudData.account !== "none") { ;
__p += '\n        <li><div class="detailsListItemLabel">' +
((__t = ( i18n.editFormLabelCredId )) == null ? '' : __t) +
'</div><input type="text" id="credId" name="credId" disabled class="disabled" value="' +
((__t = ( _.escape(formatter.getDisplayValue("credentialSelector", data.cloudData.account)) )) == null ? '' : __t) +
'" /></li>\n        ';
 } ;
__p += '\n        ';
 if (data["operating_system"] !== "none") { ;
__p += '\n        <li><div class="detailsListItemLabel">' +
((__t = ( i18n.editFormLabelOS )) == null ? '' : __t) +
'</div><input type="text" id="operatingSystem" name="operatingSystem" disabled class="disabled" value="' +
((__t = ( _.escape(formatter.getDisplayValue("osName", data["operating_system"])) )) == null ? '' : __t) +
'" /></li>\n        ';
 } ;
__p += '\n        ';
 if (data["mw-cloud-location"] !== "none") { ;
__p += '\n        <li><div class="detailsListItemLabel">' +
((__t = ( i18n.editFormLabelRegion )) == null ? '' : __t) +
'</div><input type="text" id="region" name="region" disabled class="disabled" value="' +
((__t = ( _.escape(formatter.getDisplayValue("cloudLocationSelector", data["mw-cloud-location"])) )) == null ? '' : __t) +
'" /></li>\n        ';
 } ;
__p += '\n      </ul>\n    </div>\n    <div id="cloudResourceConfig" class="collapsibleSection"></div>\n  </div>\n\n  <div class="buttonContainer editForm">\n    <button id="cancelEditBtn" type="button" class="action_icon btn btn_color_blue companion_btn cancelEdit" title="' +
((__t = ( i18n.editFormCancelLink )) == null ? '' : __t) +
'">\n      <span>' +
((__t = ( i18n.editFormCancelLink )) == null ? '' : __t) +
'</span><span class="visually-hidden">' +
((__t = ( i18n.editFormCancelLink )) == null ? '' : __t) +
'</span>\n    </button>\n    <button id="saveEditBtn" type="button" class="action_icon btn btn_color_blue companion_btn add_indent_10 save disabled" disabled=true title="' +
((__t = ( i18n.editFormSaveLink )) == null ? '' : __t) +
'">\n      <span>' +
((__t = ( i18n.editFormSaveLink )) == null ? '' : __t) +
'</span><span class="visually-hidden">' +
((__t = ( i18n.editFormSaveLink )) == null ? '' : __t) +
'</span>\n    </button>\n    ';
 if (showStartButton) { ;
__p += '\n    <button id="startEditBtn" type="button" class="action_icon btn btn_color_blue add_indent_10 start disabled" disabled=true title="' +
((__t = ( i18n.editFormStartLink )) == null ? '' : __t) +
'">\n      <span>' +
((__t = ( i18n.editFormStartLink )) == null ? '' : __t) +
'</span><span class="visually-hidden">' +
((__t = ( i18n.editFormSaveLink )) == null ? '' : __t) +
'</span>\n    </button>\n    ';
 } ;
__p += '\n  </div>\n\n</form>\n';

}
return __p
};
