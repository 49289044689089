module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="resource-status status">\n  ';
 if (useProgressSpinner) { ;
__p += '\n    <div class="progressSpinnerContainer">\n      <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n    </div>\n  ';
 } else { ;
__p += '\n    <div class="progressbar"></div>\n    <div class="statusNameContainer"></div>\n  ';
 } ;
__p += '\n</div>\n<div class="resource-countdown"></div>\n';

}
return __p
};
