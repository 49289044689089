module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '#https://docs.microsoft.com/en-us/windows-server/remote/remote-desktop-services/clients/rdp-files\nusername:s:' +
((__t = ( username )) == null ? '' : __t) +
'\nsession bpp:i:32\nwinposstr:s:0,3,0,0,800,600\ncompression:i:1\nkeyboardhook:i:2\naudiocapturemode:i:0\nvideoplaybackmode:i:1\nconnection type:i:7\nnetworkautodetect:i:1\nbandwidthautodetect:i:1\ndisplayconnectionbar:i:1\nenableworkspacereconnect:i:1\ndisable wallpaper:i:0\nallow font smoothing:i:0\nallow desktop composition:i:1\ndisable full window drag:i:0\ndisable menu anims:i:1\ndisable themes:i:0\ndisable cursor setting:i:0\nbitmapcachepersistenable:i:1\nfull address:s:' +
((__t = ( ip )) == null ? '' : __t) +
'\naudiomode:i:2\nredirectprinters:i:1\nredirectcomports:i:0\nredirectsmartcards:i:1\nredirectclipboard:i:1\nredirectposdevices:i:0\nautoreconnection enabled:i:1\nauthentication level:i:2\nprompt for credentials:i:0\nprompt for credentials on client:i:1\nnegotiate security layer:i:1\nremoteapplicationmode:i:0\nalternate shell:s:\nshell working directory:s:\ngatewayhostname:s:\ngatewayusagemethod:i:4\ngatewaycredentialssource:i:4\ngatewayprofileusagemethod:i:0\npromptcredentialonce:i:0\ngatewaybrokeringtype:i:0\nuse redirection server name:i:0\nrdgiskdcproxy:i:0\nkdcproxyname:s:\ndrivestoredirect:s:\n';

}
return __p
};
