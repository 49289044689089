module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="createWizardStepsContainer" class="wizardStepsContainer">\n  ';
 if (autoComplete === true) { ;
__p += '\n    <h1 class="wizardTitle autoComplete">' +
((__t = ( wizardTitle )) == null ? '' : __t) +
'</h1>\n    <div class="releaseLicenseWarningContainer autoComplete" style="display: none;">\n      <span class="horizontalIconContainer">\n        <div class="cautionIcon"></div>\n        <div class="releaseLicenseWarningText">\n          <span>' +
((__t = ( releaseLicenseWarningText )) == null ? '' : __t) +
'</span>\n        </div>\n      </span>\n    </div>\n    <p class="editExplanation autoComplete">\n      <span class="explanation1">' +
((__t = ( wizardExplanation )) == null ? '' : __t) +
'</span><br/>\n    </p>\n  ';
 } else { ;
__p += '\n    <h1 class="wizardTitle">' +
((__t = ( wizardTitle )) == null ? '' : __t) +
'</h1>\n    <div class="releaseLicenseWarningContainer" style="display: none;">\n      <span class="horizontalIconContainer">\n        <div class="cautionIcon"></div>\n        <div class="releaseLicenseWarningText">\n          <span>' +
((__t = ( releaseLicenseWarningText )) == null ? '' : __t) +
'</span>\n        </div>\n      </span>\n    </div>\n    <p class="editExplanation">\n      <span class="explanation1">' +
((__t = ( wizardExplanation )) == null ? '' : __t) +
'</span><br/>\n    </p>\n  ';
 } ;
__p += '\n  <div class="wizardBody">\n\n    ';
 if (autoComplete === true) { ;
__p += '\n    <div id="autoCompleteSpinnerContainer"><mw-progress-indicator type="spinner" size="xlarge"></mw-progress-indicator></div>\n    <div id="step1Container" class="wizardStepContainer autoComplete">\n    ';
 } else { ;
__p += '\n      <div id="step1Container" class="wizardStepContainer">\n    ';
 } ;
__p += '\n      <div id="section1" class="wizardSection">\n        <div id="section1Collapsible" class="collapsibleSection">\n          <label class="stepLegend">' +
((__t = ( step1Legend )) == null ? '' : __t) +
'</label>\n          ';
 if (autoComplete === false) { ;
__p += '\n          <div id="section1BusySpinnerContainer"><mw-progress-indicator type="spinner" size="xlarge"></mw-progress-indicator></div>\n          ';
 } ;
__p += '\n          <div id="step1ContentContainer" class="generatedCodeContainer" style="display:none;"></div>\n        </div>\n\n        <div id="step2Container" class="wizardStepContainer">\n          <div id="section2BusySpinnerContainer" style="display:none;"><mw-progress-indicator type="spinner" size="xlarge"></mw-progress-indicator></div>\n          <div id="step2ContentContainer" class="generatedCodeContainer"></div>\n        </div>\n\n      </div>\n\n      <div class="buttonContainer stepComponentContainer" >\n        <button id="cancelEditButton" type="button" class="btn btn_color_blue companion_btn" title="' +
((__t = ( cancelEditButtonTooltip )) == null ? '' : __t) +
'">' +
((__t = ( cancelEditButtonText )) == null ? '' : __t) +
'</button>\n        <button id="saveConfigurationButton" type="button" class="btn btn_color_blue companion_btn add_indent_10" title="' +
((__t = ( saveButtonTooltip )) == null ? '' : __t) +
'">' +
((__t = ( saveButtonText )) == null ? '' : __t) +
'</button>\n        <button id="launchConfigButton" type="button" class="btn btn_color_blue add_indent_10" title="' +
((__t = ( launchButtonTooltip )) == null ? '' : __t) +
'">' +
((__t = ( launchButtonText )) == null ? '' : __t) +
'</button>\n      </div>\n\n    </div>\n\n  </div>\n\n  <!-- config summary info -->\n  ';
 if (autoComplete === true) { ;
__p += '\n    <div id="stepsConfigInfoContainer" class="stepsConfigInfoContainer autoComplete"></div>\n  ';
 } else { ;
__p += '\n    <div id="stepsConfigInfoContainer" class="stepsConfigInfoContainer"></div>\n  ';
 } ;
__p += '\n\n  <!-- wizard completion summary -->\n  <div id="summaryContainer" class="wizardSummary" style="display: none;"></div>\n\n</div>\n';

}
return __p
};
