module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="widgetTesterPage" style="padding: 25px;">\n  <div id="widgetTestChoiceContainer">\n    <h1>' +
((__t = ( pageTitle )) == null ? '' : __t) +
'</h1>\n    <p class=' +
((__t = ( introTextStyle )) == null ? '' : __t) +
'>' +
((__t = ( introText )) == null ? '' : __t) +
'</p>\n    <label>' +
((__t = ( chooseLabel )) == null ? '' : __t) +
'</label>\n    <form id="widgetChoiceForm">\n      <ul id="widgetChoiceList">\n        <li><input type="radio" name="widgetChoice" value="examplestatictext" checked="true" id="exampleStaticTextWidget"/><label for="exampleStaticTextWidget">' +
((__t = ( exampleStaticTextWidgetTitle )) == null ? '' : __t) +
'</label></li>\n        <li><input type="radio" name="widgetChoice" value="resourcedetailstext" id="resourceDetailsTextWidget"/><label for="resourceDetailsTextWidget">' +
((__t = ( resourceDetailsTextWidgetTitle )) == null ? '' : __t) +
'</label></li>\n      </ul>\n      <div id="cssCheckboxContainer">\n        <input type="checkbox" id="useDesktopStyleCheckbox"/>\n        <label for="useDesktopStyleCheckbox">' +
((__t = ( useDesktopStyleText )) == null ? '' : __t) +
'</label>\n        <div id="desktopStyleChoiceRadioContainer" style="display:none;">\n          <input type="radio" name="desktopStyleChoice" value="mw-desktop" checked="true" id="mwDesktopChoice"/>\n          <label for="mwDesktopChoice">mw-desktop</label>\n          <input type="radio" name="desktopStyleChoice" value="mw-resizable-desktop" id="mwResizableChoice"/>\n          <label for="mwResizableChoice">mw-resizable-desktop</label>\n        </div>\n      </div>\n      <div id="cssOverrideContainer">\n        <div>\n          <input type="checkbox" id="useCssOverrideCheckbox"/>\n          <label for="useDesktopStyleCheckbox">' +
((__t = ( overrideCssText )) == null ? '' : __t) +
'</label>\n        </div>\n        <div id="cssOverrideUrlContainer">\n          <label for="cssOverrideUrl">' +
((__t = ( cssOverrideToUse )) == null ? '' : __t) +
'</label>\n          <input type="text" width="200" name="cssOverrideUrl" id="cssOverrideUrl" disabled="disabled"/>\n        </div>\n        <div id="ignoreResizeContainer">\n          <input type="checkbox" id="ignoreResizeMessagesCheckbox"/>\n          <label for="ignoreResizeMessagesCheckbox">' +
((__t = ( ignoreResizeText )) == null ? '' : __t) +
'</label>\n          <div id="iframeDimensionsContainer" style="display:none;">\n            <table>\n              <caption>IFrame Dimensions</caption>\n              <thead><tr><th scope="col"/><th scope="col"/></tr></thead>\n              <tbody>\n              <tr>\n                <td><label for="iframeDimensionWidth">' +
((__t = ( iframeWidthText )) == null ? '' : __t) +
'</label></td>\n                <td><input type="number" name="iframeWidthInPixels" id="iframeDimensionWidth" value="0" size="10"/></td>\n              </tr>\n              <tr>\n                <td><label for="iframeDimensionHeight">' +
((__t = ( iframeHeightText )) == null ? '' : __t) +
'</label></td>\n                <td><input type="number" name="iframeHeightInPixels" id="iframeDimensionHeight" value="0" size="10"/></td>\n              </tr>\n              </tbody>\n            </table>\n          </div>\n        </div>\n      </div>\n      <hr />\n      <div id="widgetChoiceButtonContainer">\n        <button type="button" class="btn btn_color_blue companion_btn" id="resetButton">' +
((__t = ( resetButtonLabel )) == null ? '' : __t) +
'</button>\n        <button type="button" class="btn btn_color_blue add_indent_10" id="runWidgetButton">' +
((__t = ( runButtonLabel )) == null ? '' : __t) +
'</button>\n      </div>\n    </form>\n  </div>\n\n  <div id="widgetTestOutputContainer">\n    <h1>' +
((__t = ( selectedWidgetContents )) == null ? '' : __t) +
'</h1>\n    <div id="selectedWidgetContainer"></div>\n  </div>\n\n</div>\n';

}
return __p
};
