module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- /* //NOSONAR */ --><li class="item item-container " data-updating="false" data-id="' +
((__t = ( params.id )) == null ? '' : __t) +
'">\n  <div class="attribute resource-machine-name editableText" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderName )) == null ? '' : __t) +
'">\n    <editable-text \n      resourceid="' +
((__t = ( params.id )) == null ? '' : __t) +
'" \n      inputfieldname="mw-name" \n      resourcetype="' +
((__t = ( params.resourceType )) == null ? '' : __t) +
'" \n      resourcenamevalue="' +
((__t = ( params.name )) == null ? '' : __t) +
'" \n      linkclassname="goToResourceDetail"\n      validator="^(\\w+).*$"\n      maxlength=50\n      editbuttontitle="' +
((__t = ( I18NStringResource.resourceNameEditButtonTitle )) == null ? '' : __t) +
'"\n      islink\n      dduxenabled>\n    </editable-text>\n  </div>\n  <div class="attribute resource-notification-count" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnNotifications )) == null ? '' : __t) +
'" data-notifications="' +
((__t = ( params.combined_notification )) == null ? '' : __t) +
'">\n    ';
 if (params.notification_count > 0)  { ;
__p += '\n      <button type="button" class="btn displayNotificationsBtn" title="' +
((__t = ( I18NStringResource.viewNotifications )) == null ? '' : __t) +
'">\n        <span class="sr-only">' +
((__t = ( I18NStringResource.displayResourceSpecificNotifications )) == null ? '' : __t) +
'</span>\n        <span class="icon-alert-error"></span>\n      </button>\n    ';
 } ;
__p += '\n  </div>\n  <div class="attribute hide-mobile-header resource-ip-address ip-address" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderIPAddress )) == null ? '' : __t) +
'">\n    <div class="progressSpinnerContainer">\n      <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n    </div>\n  </div>\n  <div class="attribute resource-operating-system" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderOperatingSystem )) == null ? '' : __t) +
'">' +
((__t = ( params.operating_system )) == null ? '' : __t) +
'</div>\n  <div class="attribute resource-cloud-provider" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderCloudProvider )) == null ? '' : __t) +
'" title="' +
((__t = ( params.cloud_provider )) == null ? '' : __t) +
' ';
 if (params.cloud_account) { ;
__p += '(' +
((__t = ( params.cloud_account )) == null ? '' : __t) +
')';
 } ;
__p += '">' +
((__t = ( params.cloud_provider )) == null ? '' : __t) +
' ';
 if (params.cloud_account) { ;
__p += '(' +
((__t = ( params.cloud_account )) == null ? '' : __t) +
')';
 } ;
__p += '</div>\n  <div class="attribute resource-cloud-location" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderCloudLocation )) == null ? '' : __t) +
'">' +
((__t = ( params.cloud_location )) == null ? '' : __t) +
'</div>\n  <div class="attribute resource-cloud-network" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderCloudNetwork )) == null ? '' : __t) +
'">' +
((__t = ( params.VPC )) == null ? '' : __t) +
'</div>\n  <div class="statusAndCountdownContainer attribute" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderStatus )) == null ? '' : __t) +
'"></div>\n  <div class="attribute hide-mobile-header resource-primary-cta cta icon-container" data-name="' +
((__t = ( I18NStringResource.computeResourcePageColumnHeaderActions )) == null ? '' : __t) +
'">\n    <div class="progressSpinnerContainer">\n      <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n    </div>\n  </div>\n  <div class="attribute hide-mobile-header resource-menu context-menu menu-container" data-name="">\n    <div class="progressSpinnerContainer">\n      <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n    </div>\n  </div>\n</li>\n';

}
return __p
};
