module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '# https://docs.aws.amazon.com/dcv/latest/userguide/client.html\n# https://docs.aws.amazon.com/dcv/latest/userguide/using-connection-file.html\n\n[version]\nformat=1.0\n\n[connect]\nhost=' +
((__t = ( ip )) == null ? '' : __t) +
'\nport=8443\nweburlpath=\nsessionid=\nuser=' +
((__t = ( username )) == null ? '' : __t) +
'\npassword=' +
((__t = ( password )) == null ? '' : __t) +
'\nproxyhost=\nproxyport=0\nproxytype=none\ncertificatevalidationpolicy=accept-untrusted\n\n[options]\nenableyuv444decoding=true\nfullscreen=false\npromptreconnect=true\nuseallmonitors=false';

}
return __p
};
