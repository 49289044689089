module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<!-- Mobile TopNav: Start -->\n<div class="header d-lg-none blockContainer" id="header_mobile">\n  <nav class="navbar navbar-expand-lg navbar-dark" aria-label="nav_mobile">\n    <div class="container-fluid">\n      <div class="navbar-header">\n            <button type="button" class="navbar-toggler topnav_toggle" data-bs-toggle="collapse" data-bs-target="#topnav_collapse">\n              <span class="visually-hidden">' +
((__t = ( toggleNavigationLabel )) == null ? '' : __t) +
'</span>\n              <span class="icon-menu"></span>\n            </button>\n            <div class="navbar-brand ms-auto">\n              <button type="button" class="' +
((__t = ( logoButtonClasses )) == null ? '' : __t) +
'" >\n                <span class="visually-hidden">' +
((__t = ( cloudCenterLogoText )) == null ? '' : __t) +
'</span>\n                <div class="appTitle">\n                  <div class="cloudCenterLogo">' +
((__t = ( cloudCenterLogoText )) == null ? '' : __t) +
'</div>\n                </div>\n              </button>\n            </div>\n            <div id="matrix_affordance_container_mobile" class="nav-item ms-auto headernav_matrix"></div>\n        </div>\n        <div class="collapseContainer">\n          <div class="navbar-collapse collapse" id="matrix_collapse">\n            <div class="matrix_menu_container_mobile" id="matrix_menu_container_mobile"></div>\n          </div>\n          <div class="navbar-collapse collapse matlabDriveUserName" id="topnav_collapse">\n            <ul class="nav navbar-nav me-auto" style="padding-top: 0px !important;" id="topnav">\n              <li class="nav-item headernav_login dropdown">\n                <a href="#" class="dropdown-toggle" class="nav-link" data-bs-toggle="dropdown">\n                  <span class="visually-hidden">' +
((__t = ( myAccountLabel )) == null ? '' : __t) +
'</span>\n                  ' +
((__t = ( myAccountOrNameLabel )) == null ? '' : __t) +
'\n                </a>\n                <ul class="dropdown-menu" id="cloud_center_nav_mobile">\n                  <li class="aboutMenuContainer"><a id="menu-about" class="dropdown-item" role="menuitem" href="#">' +
((__t = ( aboutLabel )) == null ? '' : __t) +
'</a></li>\n                  <!-- <li class="managementMenuContainer"><a id="menu-management" class="dropdown-item" role="menuitem" href="#">' +
((__t = ( managementLabel )) == null ? '' : __t) +
'</a></li> -->\n                  <li class="logoutContainer"><a id="menu-logout" class="dropdown-item" role="menuitem" href="#">' +
((__t = ( logoutLabel )) == null ? '' : __t) +
'</a></li>\n                </ul>\n              </li>\n              <li class="headernav_help"><a href="https://www.mathworks.com/help/cloudcenter/mathworks-cloud-center.html?s_tid=CC_CCSI" rel="noopener noreferrer" target="_blank" id="helpLink" class="dropdown-item"><span class="horizontalIconContainer"><div class="helpBlueIcon"></div></span></a></li>\n            </ul>\n          </div>\n        </div>\n\n    </div>\n  </nav>\n</div>\n<!-- Mobile TopNav: End -->\n<!-- Desktop TopNav: Start -->\n<div class="header d-none d-lg-block blockContainer" id="header_desktop">\n  <nav class="navbar navbar-expand-lg navbar-light" aria-label="nav_desktop">\n    <div class="container-fluid">\n\n          <div class="navbar-brand">\n            <button type="button" class="' +
((__t = ( logoButtonClasses )) == null ? '' : __t) +
'" >\n              <span class="visually-hidden">' +
((__t = ( cloudCenterLogoText )) == null ? '' : __t) +
'</span>\n              <div class="appTitle">\n                  <div class="cloudCenterLogo">' +
((__t = ( cloudCenterLogoText )) == null ? '' : __t) +
'</div>\n              </div>\n            </button>\n          </div>\n\n          <div class="collapse navbar-collapse matlabDriveUserName" id="headernav_collapse">\n            <ul class="nav navbar-nav me-auto mb-2 mb-lg-0" style="padding-top: 0px !important;" id="headernav">\n              <li class="nav-item headernav_help"><a href="https://www.mathworks.com/help/cloudcenter/mathworks-cloud-center.html?s_tid=CC_CCSI" rel="noopener noreferrer" target="_blank" id="helpLink"><span class="nav-link horizontalIconContainer"><div class="helpIcon"></div></span></a></li>\n              <li class="nav-item headernav_login dropdown">\n                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="menu1">\n                  <span class="visually-hidden">' +
((__t = ( myAccountLabel )) == null ? '' : __t) +
'</span>\n                  ' +
((__t = ( myAccountOrNameLabel )) == null ? '' : __t) +
'\n                </a>\n                <ul class="dropdown-menu" id="cloud_center_nav">\n                  <li class="aboutMenuContainer"><a id="menu-about" class="dropdown-item" href="#">' +
((__t = ( aboutLabel )) == null ? '' : __t) +
'</a></li>\n                  <!-- <li class="managementMenuContainer"><a id="menu-management" class="dropdown-item" href="#">' +
((__t = ( managementLabel )) == null ? '' : __t) +
'</a></li> -->\n                  <li class="logoutContainer"><a id="menu-logout" class="dropdown-item" href="#">' +
((__t = ( logoutLabel )) == null ? '' : __t) +
'</a></li>\n                </ul>\n              </li>\n              <li id="headernav_matrix" class="nav-item headernav_matrix"></li>\n            </ul>\n          </div>\n\n    </div>\n  </nav>\n</div>\n<!-- Desktop TopNav: End -->\n\n<div id="notificationArea" class="notificationsWrapper importantMessage"></div>\n';

}
return __p
};
