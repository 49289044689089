module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<!-- step 1 -->\n<div id="section1SummaryBusySpinnerContainer" style="display: flex;"><mw-progress-indicator type="spinner" size="xlarge" style="" indeterminate="" role="progressbar" tabindex="0"><!---->\n  <svg class="progressIndicatorSvg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="-32 -32 64 64">\n  <g>\n  <circle class="mw-progress-indicator-gutter" r="27.5" cx="0" cy="0" stroke-width="9"></circle>\n  <circle class="mw-progress-indicator  mw-stroke-default-color" r="27.5" cx="0" cy="0" stroke-width="9" stroke-dasharray="43.19689898685966, 172.78759594743863" stroke="var(--mw-backgroundColor-info, #0095ff)"></circle>\n  <circle class="mw-progress-indicator mw-progress-indicator-border " r="27.5" cx="0" cy="0" stroke-width="9" stroke-dasharray="1, 42.19689898685966, 1, 171.78759594743863"></circle>\n  </g>\n  </svg><!----></mw-progress-indicator>\n</div>\n<div id="nameContainer" class="stepComponentContainer">\n  <label for="mlNameInput">' +
((__t = ( nameLabel )) == null ? '' : __t) +
'</label>\n  <p class="finePrint">' +
((__t = ( nameFinePrintText )) == null ? '' : __t) +
'</p>\n  <input type="text" name="mlNameInput" id="mlNameInput" maxlength="' +
((__t = ( nameMaxLength )) == null ? '' : __t) +
'"/>\n</div>\n<div id="selectReleaseContainer" class="stepComponentContainer">\n  <label for="releaseSelector">' +
((__t = ( releaseLabel )) == null ? '' : __t) +
'</label>\n  <p class="finePrint">' +
((__t = ( releaseFinePrintText )) == null ? '' : __t) +
'</p>\n  <select name="releaseSelector" id="releaseSelector" disabled>\n    <option value="" disabled selected hidden>' +
((__t = ( releasePromptText )) == null ? '' : __t) +
'</option>\n  </select>\n  <button type="button" class="warning-notification deprecated" style="display:none;"><span class="icon-alert-warning"></span></button>\n  <button type="button" class="warning-notification sunsetted" style="display:none;"><span class="icon-alert-warning"></span></button>\n</div>\n<div id="selectCloudPlatformContainer" class="stepComponentContainer">\n  <label>' +
((__t = ( selectCloudPlatformLabel )) == null ? '' : __t) +
'</label>\n  <select name="cloudChoice" id="cloudChoice" disabled>\n    <option value="" disabled selected hidden>' +
((__t = ( selectCloudPlatformLabel )) == null ? '' : __t) +
'</option>\n  </select>\n</div>\n<div id="selectCredentialsContainer" class="stepComponentContainer">\n  <label for="credentialSelector">' +
((__t = ( selectCredentialLabel )) == null ? '' : __t) +
'</label>\n  <select name="credentialSelector" id="credentialSelector" disabled>\n    <option value="" disabled selected hidden>' +
((__t = ( selectCredentialLabel )) == null ? '' : __t) +
'</option>\n  </select>\n</div>\n<div id="selectOSContainer" class="stepComponentContainer">\n  <label for="osChoices">' +
((__t = ( selectOSLabel )) == null ? '' : __t) +
'</label>\n  <select name="osChoice" id="osChoice" disabled>\n    <option value="" disabled selected hidden>' +
((__t = ( selectOSLabel )) == null ? '' : __t) +
'</option>\n  </select>\n</div>\n<div id="cloudLocationSelectorContainer" class="stepComponentContainer">\n  <label for="cloudLocationSelector">' +
((__t = ( selectCloudLocationLabel )) == null ? '' : __t) +
'</label>\n  <select name="cloudLocationSelector" id="cloudLocationSelector" disabled></select>\n</div>\n<div id="descriptionContainer" class="stepComponentContainer" style="display: none;">\n  <label for="descriptionSelector">' +
((__t = ( chooseDescriptionLabel )) == null ? '' : __t) +
'</label>\n  <select name="descriptionSelector" id="descriptionSelector" disabled>\n    <option value="" disabled selected hidden>' +
((__t = ( chooseDescriptionText )) == null ? '' : __t) +
'</option>\n  </select>\n</div>';

}
return __p
};
