module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="inputPromptDialog" class="modal fade inputPromptDialog" tabindex="-1">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title">' +
__e( dialogTitle ) +
'</h4>\n                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body">\n              <p class="explanationText">' +
((__t = ( explanation )) == null ? '' : __t) +
'</p>\n              <div class="errorTextContainer">\n                <div class="validationErrorImage"></div>\n                <p class="errorText"></p>\n              </div>\n              <form name="inputPromptForm">\n                ';
 if (hiddenArray && Array.isArray(hiddenArray) && hiddenArray.length) {
                    for (let hiddenData of hiddenArray) { ;
__p += '\n                  <input type="hidden" name=' +
((__t = ( hiddenData.id )) == null ? '' : __t) +
' id=' +
((__t = ( hiddenData.id )) == null ? '' : __t) +
' value=' +
((__t = ( hiddenData.value )) == null ? '' : __t) +
' />\n                ';
 } } ;
__p += '\n                <div class="fieldTable">\n                  ';
 if (inputArray && Array.isArray(inputArray) && inputArray.length) {
                      for (let inputData of inputArray) { ;
__p += '\n                        <div class="fieldTableRow">\n                          <label for="">' +
((__t = ( inputData.label )) == null ? '' : __t) +
'</label>\n                          ';
 if (inputData.type === "textarea") { ;
__p += '\n                            <textarea name=' +
((__t = ( inputData.id )) == null ? '' : __t) +
' id=' +
((__t = ( inputData.id )) == null ? '' : __t) +
' rows="2" cols="80" ' +
((__t = ( (inputData.maxlength && inputData.maxlength>0)?"maxlength=\""+inputData.maxlength+"\"":"" )) == null ? '' : __t) +
'>' +
((__t = ( inputData.value )) == null ? '' : __t) +
'</textarea>\n                          ';
 } else if (inputData.type === "select") { ;
__p += '\n                            <select name=' +
((__t = ( inputData.id )) == null ? '' : __t) +
' id=' +
((__t = ( inputData.id )) == null ? '' : __t) +
' >\n                              ';
 for (let option of inputData.options) { ;
__p += '\n                                <option value="' +
((__t = ( option )) == null ? '' : __t) +
'">' +
((__t = ( option )) == null ? '' : __t) +
'</option>\n                              ';
 };
__p += '\n                            </select>\n                          ';
 } else { ;
__p += '\n                            <input type="text" placeholder="' +
((__t = ( inputData.placeholder )) == null ? '' : __t) +
'" name=' +
((__t = ( inputData.id )) == null ? '' : __t) +
' value="' +
((__t = ( inputData.value )) == null ? '' : __t) +
'" ' +
((__t = ( (inputData.maxlength && inputData.maxlength>0)?"maxlength=\""+inputData.maxlength+"\"":"" )) == null ? '' : __t) +
'id=' +
((__t = ( inputData.id )) == null ? '' : __t) +
' />\n                          ';
 } ;
__p += '\n                        </div>\n                  ';
 } } ;
__p += '\n                </div>\n              </form>\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="cancelButton" class="btn btn_color_blue companion_btn cancelBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( cancelButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" id="actionButton" class="btn btn_color_blue add_indent_10 actionBtn" tabindex="0">' +
((__t = ( actionButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};
