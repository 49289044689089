module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="connectCredentialsDialog" class="modal fade inputPromptDialog" tabindex="-1">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title">' +
__e( dialogTitle ) +
'</h4>\n                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body">\n              <p class="explanationText">\n                ';
 if (access==="NICE DCV") { ;
__p += '\n                  ' +
((__t = ( connectToDCVExplanation )) == null ? '' : __t) +
'\n                ';
 } else if (access==="DCV_FILE") { ;
__p += '\n                  ' +
((__t = ( downloadDCVFileExplanation )) == null ? '' : __t) +
'\n                ';
 } else { ;
__p += '\n                  ' +
((__t = ( connectToRDPExplanation )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n              </p>\n\n              <p class="explanationText">' +
((__t = ( explanation )) == null ? '' : __t) +
'</p>\n              <div class="errorTextContainer">\n                <div class="validationErrorImage"></div>\n                <p class="errorText"></p>\n              </div>\n              ';
 if (access === "DCV_FILE") {
                access = "NICE DCV";
              }
              if (os==="linux" && currentAccess && access != currentAccess) { ;
__p += '\n              <div class="releaseLicenseWarningContainer">\n                <span class="horizontalIconContainer">\n                  <div class="cautionIcon"></div>\n                  <div class="releaseLicenseWarningText">\n                    <span>';

                      if (currentAccess==="NICE DCV") {
                        ;
__p +=
((__t = ( connectToRDP )) == null ? '' : __t);

                      } else {
                        ;
__p +=
((__t = ( connectToDCV )) == null ? '' : __t);

                      }
                  ;
__p += '</span>\n                  </div>\n                </span>\n              </div>\n              ';
 } ;
__p += '\n              <form name="connectCredentialsForm">\n                <ul>\n                  <li><label for="username">' +
((__t = ( usernameLabel )) == null ? '' : __t) +
'</label><div class="attributeValue" id="username">' +
((__t = ( username )) == null ? '' : __t) +
'</div></li>\n                  <li>\n                    <label for="password">' +
((__t = ( passwordLabel )) == null ? '' : __t) +
'</label>\n                    <input type="password" name="password" id="password" autocomplete="current-password" disabled="disabled" readonly value=' +
((__t = ( password )) == null ? '' : __t) +
' />\n                    <button type="button" class="btn btn_color_blue companion_btn viewPassword" title="' +
((__t = ( showHidePasswordText )) == null ? '' : __t) +
'">\n                      <span class="visually-hidden">' +
((__t = ( showHidePasswordText )) == null ? '' : __t) +
'</span>\n                      <div class="viewPasswordIcon smallIcon"></div>\n                    </button>\n                  </li>\n                </ul>\n              </form>\n              ';
 if (footer) { ;
__p += '\n              <div class="row">\n              ';
 if (footerLabel) { ;
__p += '\n                <div class="col-md-1 ">\n                <label for="footerWarning" class="text-nowrap">' +
((__t = ( footerLabel )) == null ? '' : __t) +
'</label>\n                </div>\n                <div class="col-md-11">\n              ';
 } ;
__p += '\n                <span id="footerWarning">' +
((__t = ( footer )) == null ? '' : __t) +
'</span>\n              ';
 if (footerLabel) { ;
__p += '\n                </div>\n              ';
 } ;
__p += '\n              </div>\n              ';
 } ;
__p += '\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="cancelButton" class="btn btn_color_blue companion_btn cancelBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( cancelButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" id="copyButton" class="btn btn_color_blue add_indent_10 actionBtn" tabindex="0">' +
((__t = ( copyButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};
