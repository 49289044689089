module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div id="copyToClipboardDialog" class="modal fade inputPromptDialog" tabindex="-1">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title">' +
__e( dialogTitle ) +
'</h4>\n                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body">\n              <p class="explanationText">' +
((__t = ( explanation )) == null ? '' : __t) +
'</p>\n              <div class="errorTextContainer">\n                <div class="validationErrorImage"></div>\n                <p class="errorText"></p>\n              </div>\n              <form name="copyToClipboardForm">\n                <input type="text" name="textToCopy" id="textToCopy" disabled="disabled" value=' +
((__t = ( copyText )) == null ? '' : __t) +
' />\n              </form>\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="cancelButton" class="btn btn_color_blue companion_btn cancelBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( cancelButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" id="copyButton" class="btn btn_color_blue add_indent_10 actionBtn" tabindex="0">' +
((__t = ( copyButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};
