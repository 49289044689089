module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="resourceDetailContainer">\n  <h1 class="resourceDetailContainerTitle">' +
((__t = ( resourceDetailContainerTitle )) == null ? '' : __t) +
'</h1>\n  <div class="mainDetailTable">\n    <dl class="row mainDetailTableList"></dl>\n  </div>\n  <div class="additionalDetailContainer">\n    <h2>' +
((__t = ( additionDetailSectionTitle )) == null ? '' : __t) +
'</h2>\n    <div id="shortAdditionalDetail" class="shortAddtionalDetail" style="display:none;">\n      <p></p>\n    </div>\n    <div id="additionalDetailGeneratedCode" class="generatedCode">\n    </div>\n  </div>\n</div>\n';

}
return __p
};
