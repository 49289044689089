module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="countdownIndicator form-inline countdown-form-inline hide">\n  <label for="updateTerminationPolicyValue" class="timeoutPolicyLabel">' +
((__t = ( timeoutPolicyLabel )) == null ? '' : __t) +
'</label>\n  <select id="updateTerminationPolicyValue" class="form-inline countdown-form-inline form-control">\n    ';
 for (const termPol of terminationValues) { ;
__p += '\n      <option value="' +
((__t = ( termPol.k )) == null ? '' : __t) +
'">' +
((__t = ( termPol.v )) == null ? '' : __t) +
'</option>\n    ';
 } ;
__p += '\n\n  </select>\n  <button\n    type="button"\n    id="updateTerminationPolicyButton"\n    title="' +
((__t = ( terminationPolicyResetTimerButtonTooltip )) == null ? '' : __t) +
'"\n    class="form-inline form-control btn btn_color_blue btn-sm">' +
((__t = ( terminationPolicyResetTimerButton )) == null ? '' : __t) +
'\n  </button>\n</div>\n';

}
return __p
};
