module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- step 3 -->\n<div id="wizardCompleteSummary" class="wizardSummary" style="display:none;">\n  ';
 if (licenseRequired) { ;
__p += '\n  <div class="releaseLicenseWarningContainer">\n    <span class="horizontalIconContainer">\n      <div class="cautionIcon"></div>\n      <div class="releaseLicenseWarningText">\n        <span>' +
((__t = ( releaseLicenseWarningText )) == null ? '' : __t) +
'</span>\n      </div>\n    </span>\n  </div>\n  ';
 } ;
__p += '\n  <h1>' +
((__t = ( description )) == null ? '' : __t) +
'</h1>\n  <div class="summaryListContainer">\n    <h2>' +
((__t = ( wizardSummarySubTitle )) == null ? '' : __t) +
'</h2>\n    <dl class="row wizardCompleteSummaryList"></dl>\n    <div class="additionalDetailContainer">\n      <h2>' +
((__t = ( additionDetailSectionTitle )) == null ? '' : __t) +
'</h2>\n      <div id="shortAdditionalDetail" class="shortAddtionalDetail" style="display:none;">\n        <p></p>\n      </div>\n      <div id="additionalDetailGeneratedCode" class="generatedCode">\n      </div>\n    </div>\n  </div>\n  <div id="summaryButtons" class="buttonContainer stepComponentContainer">\n    <button id="cancelSummaryButton" type="button" class="btn btn_color_blue companion_btn" title="' +
((__t = ( closeSummaryButtonTooltip )) == null ? '' : __t) +
'" >' +
((__t = ( closeSummaryButtonText )) == null ? '' : __t) +
'</button>\n    ';
 if (autoCompleteOn) { ;
__p += '\n    <button id="editButton" type="button" class="btn btn_color_blue companion_btn add_indent_10 disabled" title="' +
((__t = ( editButtonTooltip )) == null ? '' : __t) +
'" disabled>\n      <span class="progressSpinnerContainer"><mw-progress-indicator type="spinner" size="xsmall"></mw-progress-indicator></span>\n      <span id="editLinkText">' +
((__t = ( editLinkTitle )) == null ? '' : __t) +
'</span>\n    </button>\n    ';
 } ;
__p += '\n    <button id="saveConfigButton" type="button" class="btn btn_color_blue companion_btn add_indent_10 disabled" title="' +
((__t = ( saveConfigButtonTooltip )) == null ? '' : __t) +
'" disabled>' +
((__t = ( saveConfigButtonText )) == null ? '' : __t) +
'</button>\n    <button id="launchConfigButton" type="button" class="btn btn_color_blue add_indent_10 disabled" title="' +
((__t = ( launchButtonTooltip )) == null ? '' : __t) +
'" disabled>' +
((__t = ( launchButtonText )) == null ? '' : __t) +
'</button>\n  </div>\n</div>\n';

}
return __p
};
