module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="input-group mb-2 mt-2">\n  <div class="input-group-text" id="descLabel">' +
((__t = ( label )) == null ? '' : __t) +
'</div>\n  <input type="text" class="form-control input-group-text" id="label' +
((__t = ( id )) == null ? '' : __t) +
'" value="' +
((__t = ( value )) == null ? '' : __t) +
'" readonly="readonly" />\n  <span class="input-group-btn">\n    <button class="btn btn_color_blue copyIPValueButton ' +
((__t = ( id )) == null ? '' : __t) +
'Button" type="button" id="' +
((__t = ( id )) == null ? '' : __t) +
'Copy" data-bs-toggle="tooltip" data-bs-placement="bottom" title="' +
((__t = ( tooltip )) == null ? '' : __t) +
'">\n        <div class="copyTextIcon"></div>\n    </button>\n  </span>\n</div>\n';

}
return __p
};
