module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<!-- Mobile TopNav: Start -->\n<div class="header d-lg-none blockContainer" id="header_mobile">\n    <div class="container-fluid">\n      <div class="navbar-header">\n          <button type="button" class="btn btn-link default-link">\n            <div class="appTitle">\n              <div class="cloudCenterLogo">' +
((__t = (cloudCenterLogoText)) == null ? '' : __t) +
'</div>\n            </div>\n          </button>\n      </div>\n    </div>\n</div>\n<!-- Mobile TopNav: End -->\n<!-- Desktop TopNav: Start -->\n<div class="header d-none d-lg-block blockContainer" id="header_desktop_preload">\n    <div class="container-fluid">\n          <div class="navbar-brand">\n            <button type="button" class="btn btn-link default-link btn-logo">\n                <div class="appTitle">\n                    <div class="cloudCenterLogo">' +
((__t = (cloudCenterLogoText)) == null ? '' : __t) +
'</div>\n                  </div>\n            </button>\n          </div>\n    </div>\n</div>\n<!-- Desktop TopNav: End -->\n\n<div id="pageNavPreloadSpinnerContainer"><mw-progress-indicator type="spinner" size="xlarge"></mw-progress-indicator></div>';

}
return __p
};
