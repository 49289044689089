module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="computeViewHeader" class="headerContainer">\n\n    ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n</div>\n\n<div id="computeResourceDetailPageView" class="pageView">\n\n  <div class="facetBar" id="computeResourceDetailPageFacetPicker">\n    <ul class="facets">\n      <li><a id="resourceLink" href="javascript:void(0)">' +
((__t = ( computeLinkText )) == null ? '' : __t) +
'</a></li>\n      <li><a id="credentialLink" href="javascript:void(0)">' +
((__t = ( credentialLinkText )) == null ? '' : __t) +
'</a></li>\n    </ul>\n  </div>\n\n  <div id="detailsBusySpinnerContainer"><mw-progress-indicator type="spinner" size="xlarge"></mw-progress-indicator></div>\n\n  <div id="computeDetailsContainer" class="computeDetailsContainer" style="display: none;">\n\n      <h1 class="pageTitle">' +
((__t = ( computeResourceDetailPageTitle )) == null ? '' : __t) +
'</h1>\n\n      <button type="button" class="btn displayNotificationsBtn" style="display:none;" title="' +
((__t = ( displayResourceSpecificNotifications )) == null ? '' : __t) +
'">\n        <span class="visually-hidden">' +
((__t = ( displayResourceSpecificNotifications )) == null ? '' : __t) +
'</span>\n        <span class="icon-alert-error"></span>\n      </button>\n\n      <div class="statusAndCountdownContainer"></div>\n\n      <div class="timeoutDropdownContainer" style="display:none;"></div>\n\n    <div class="releaseLicenseWarningContainer" style="display: none;">\n      <span class="horizontalIconContainer">\n        <div class="cautionIcon"></div>\n        <div class="releaseLicenseWarningText">\n          <span>' +
((__t = ( releaseLicenseWarningText )) == null ? '' : __t) +
'</span>\n        </div>\n      </span>\n    </div>\n\n    <div id="providerResourceStatusContainer" class="providerResourceStatusContainer">\n      <span class="providerResourceStatusLabel">' +
((__t = ( providerResourceStatusLabel )) == null ? '' : __t) +
'</span>\n      <span class="providerResourceStatusValue"></span>\n    </div>\n\n    <div id="generatedCode" class="generatedCode"></div>\n    <div id="computeResourceConfigEditContainer" class="editFormContainer" style="display:none;"></div>\n\n    <div class="actionIconContainer detailsPage">\n      <button id="detailsEditBtn" type="button" class="action_icon btn btn_color_blue companion_btn edit" title="' +
((__t = ( editButtonTooltip )) == null ? '' : __t) +
'" style="display:none;">\n        <span class="visually-hidden">' +
((__t = ( editActionLabel )) == null ? '' : __t) +
'</span>\n        <span class="iconText">' +
((__t = ( editActionLabel )) == null ? '' : __t) +
'</span>\n      </button>\n      <button id="detailsStartBtn" type="button" class="action_icon btn btn_color_blue add_indent_10 start" title="' +
((__t = ( startButtonTooltip )) == null ? '' : __t) +
'" style="display:none;">\n        <span class="visually-hidden">' +
((__t = ( startActionLabel )) == null ? '' : __t) +
'</span>\n        <span class="iconText">' +
((__t = ( startActionLabel )) == null ? '' : __t) +
'</span>\n      </button>\n      <button id="detailsResumeBtn" type="button" class="action_icon btn btn_color_blue add_indent_10 resume" title="' +
((__t = ( resumeButtonTooltip )) == null ? '' : __t) +
'" style="display:none;">\n        <span class="visually-hidden">' +
((__t = ( resumeActionLabel )) == null ? '' : __t) +
'</span>\n        <span class="iconText">' +
((__t = ( resumeActionLabel )) == null ? '' : __t) +
'</span>\n      </button>\n      <button id="detailsPauseBtn" type="button" class="action_icon btn btn_color_blue add_indent_10 pause" title="' +
((__t = ( pauseButtonTooltip )) == null ? '' : __t) +
'" style="display:none;">\n        <span class="visually-hidden">' +
((__t = ( pauseActionLabel )) == null ? '' : __t) +
'</span>\n        <span class="iconText">' +
((__t = ( pauseActionLabel )) == null ? '' : __t) +
'</span>\n      </button>\n      <button id="detailsStopBtn" type="button" class="action_icon btn btn_color_blue add_indent_10 stop" title="' +
((__t = ( stopButtonTooltip )) == null ? '' : __t) +
'" style="display:none;">\n        <span class="visually-hidden">' +
((__t = ( stopActionLabel )) == null ? '' : __t) +
'</span>\n        <span class="iconText">' +
((__t = ( stopActionLabel )) == null ? '' : __t) +
'</span>\n      </button>\n    </div>\n\n  </div>\n\n</div>\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
